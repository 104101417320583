/** @jsx jsx */
import { Styled, jsx } from "theme-ui";
// import { Link } from "gatsby";

import { graphql, StaticQuery } from "gatsby";

// import Container from "./Container";

export default () => (
  <div
    sx={{
      borderRight: "1px solid",
      borderColor: "muted",
      p: 2,
      minWidth: 'pagesidebar'
    }}
  >
    <StaticQuery
      query={graphql`
        query SidebarGebieteQuery {
          allMarkdownRemark(
            filter: {
              fileAbsolutePath: { regex: "/rechtsgebiet/" }
              frontmatter: { name: { ne: "rechtsgebiete" } }
            }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  name
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div>
          {data.allMarkdownRemark.edges.map((document) => (
            <a
              href={document.node.fields.slug}
              key={document.node.frontmatter.name}
            >
              <Styled.h5>{document.node.frontmatter.name}</Styled.h5>
            </a>
          ))}
        </div>
      )}
    />
  </div>
);
